<template>
  <div v-if="cookiesVisible" class="backdrop CookieBox">
    <main class="modal">
      <div class="modalContent">
        <font-awesome-icon @click="closeCookies" class="x" icon="fa-solid fa-x" />
        <the-cookie></the-cookie>
        <div class="contentText">
          <h1>Nasza strona używa cookies</h1>
          <h2>
            Serwis wykorzystuje pliki cookies m.in. w celu poprawienia jej dostępności, personalizacji, obsługi kont użytkowników czy aby zbierać dane, dotyczące ruchu na stronie. Każdy może sam decydować o tym czy dopuszcza pliki cookies, ustawiając odpowiednio swoją przeglądarkę.
            Więcej informacji znajdziesz w Polityce <a href="">Prywatności i Regulaminie.</a> 
          </h2>
        </div>
      </div>
      <div class="modalNav">
        <button @click="acceptCookies"><font-awesome-icon icon="fa-solid fa-cookie-bite" /> Akceptuj</button>
        <button @click="closeCookies">Anuluj</button>
      </div>
    </main>
  </div>
</template>

<script>
import TheCookie from './TheCookie.vue';
export default {
  emits:['modal-handler'],
  components:{
    TheCookie
  },
  data(){
    return{
      cookiesVisible: true,
    };
  },
  methods: {
    closeCookies(){
      this.cookiesVisible = false
    },
    acceptCookies(){
      this.cookiesVisible = false
      document.cookie = "CookieBy=Nicode.pl; max-age=" + 60 * 60 * 24 * 30;
    },
  },
  beforeMount(){
    if (document.cookie.indexOf("CookieBy=Nicode.pl") != -1){
      this.cookiesVisible = false;
    }else{
      this.cookiesVisible = true;
    }
  },
}
</script>

<style>
.hide{
  display: none !important;
}
.backdrop{
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal{
  opacity: 0;
  transform: translateY(200px) scale(0.7);
  position: fixed;
  height: auto;
  max-width: 400px;
  border-radius: 20px;
  background: #FFFFFF;
  border: 1px solid #D9DBE9;
  box-shadow: 0px 14px 42px rgba(8, 15, 52, 0.06);
  overflow: hidden;
  animation: showModal 0.5s forwards;
  animation-delay: 1s;
}
@keyframes showModal {
  0%{
    opacity: 0;
    transform: translateY(200px) scale(0.7);
  }
  100%{
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
.modalContent{
  position: relative;
  display: flex;
  align-content: center;
  justify-content: space-around;
  flex-direction: column;
  padding: 2rem;
}
.modalContent .x{
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 1.3rem;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-color: #B4165F;
  padding: 0.5rem;
  border-radius: 100%;
  color: white;
  transition: 0.3s transform;
}
.modalContent .x:hover{
  transform: scale(1.1);
}
.contentText{
  text-align: center;
}
.contentText h1{
  font-size: 1.4rem;
  font-weight: 500;
  margin: 1rem 0;
}
.contentText h2{
  font-size: 1rem;
  font-weight: 300;
}
.contentText a{
  color: #B4165F;
  text-decoration: none;
}
.modalNav{
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 1rem 2rem;
  background-color: #F7F7FB;
}
.modalNav button{
  font-size: 1rem;
  padding: 18px 26px;
  border-radius: 50px;
  border: none;
  box-shadow: 0px 3px 12px rgba(74, 58, 255, 0.18);
  cursor: pointer;
  transition: transform 0.3s ;
}
.modalNav button:hover{
  transform: scale(1.05);
}
.modalNav button:first-child{
  background-color: #B4165F;
  color: white;
}
.modalNav button:nth-child(2){
  background-color: white;
  color: black;
}
</style>