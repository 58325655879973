<template>
    <div class="burger">
        <nav-burger
            :isOpen="isOpen"
            @click="navHandler()"
        ></nav-burger>
    </div>
    <div class="nav">
        <div @click="navHandler(isOpen)" class="links textLinks">
            <a href="#box1"><btn-btn :sizeX="'12px'" :sizeY="'12px'" :color="'white'" :text="'Obsługa informatyczna firm'"></btn-btn></a>
        </div>
        <div @click="navHandler()" class="links textLinks">
            <a href="#box2"><btn-btn :sizeX="'12px'" :sizeY="'12px'" :color="'white'" :text="'Monitoring Infrastruktury'"></btn-btn></a>
        </div>
        <div @click="navHandler()" class="links textLinks">
            <a href="#box3"><btn-btn :sizeX="'12px'" :sizeY="'12px'" :color="'white'" :text="'Wdrażanie RODO'"></btn-btn></a>
        </div>
        <div @click="navHandler()" class="links textLinks">
            <a href="#box4"><btn-btn :sizeX="'12px'" :sizeY="'12px'" :color="'white'" :text="'Sieci komputerowe'"></btn-btn></a>
        </div>
    </div>
    <div class="navBackdrop">
        <div class="nav2">
            <div class="links">
                <a href="#box1"><btn-btn :sizeX="'12px'" :sizeY="'12px'" :color="'white'"></btn-btn></a>
            </div>
            <div class="links">
                <a href="#box2"><btn-btn :sizeX="'12px'" :sizeY="'12px'" :color="'white'"></btn-btn></a>
            </div>
            <div class="links">
                <a href="#box3"><btn-btn :sizeX="'12px'" :sizeY="'12px'" :color="'white'"></btn-btn></a>
            </div>
            <div class="links">
                <a href="#box4"><btn-btn :sizeX="'12px'" :sizeY="'12px'" :color="'white'"></btn-btn></a>
            </div>
        </div>
    </div>
</template>

<script>
import navBurger from './TheSideNavBurger.vue';
export default {
    components:{
        navBurger
    },
    data(){
        return{
            isOpen: false,
        };
    },
    methods:{
        navHandler(){
            const nav = document.querySelector('.nav');
            const backdropNav = document.querySelector('.nav2')
            this.isOpen = !this.isOpen;
            /* this.linksAnimate(); */
            if(this.isOpen){
                nav.style.transform = 'translateX(0%)';
                backdropNav.style.transform = 'translateX(100%)';
            }else{
                nav.style.transform = 'translateX(100%)';
                backdropNav.style.transform = 'translateX(0%)';
            }  
        },
        linksAnimate(){
            const navLinks = document.querySelectorAll('.textLinks')
            navLinks.forEach((link, index) => {
                if (link.style.animation) {
                    link.style.animation = '';
                } else {
                    link.style.animation = `navLinkFade 0.5s ease forwards ${index / 7 + 0.3}s`;
                }
            });
        },
    },
}
</script>

<style scoped>
.burger{
    position: fixed;
    right: 3%;
    top: 45%;
    z-index: 2;
}
.nav{
    position: fixed;
    height: calc(100vh - 140px);
    width: 30%;
    right: 0;
    top: 140px;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-content: right;
    justify-content: center;
    flex-direction: column;
    padding: 0 3%;
    border-left-color: white 1px solid;
    transform: translateX(100%);
    transition: 0.3s all;
    z-index: 1;
}
.nav2{
    transition: 0.3s all;
}
.navBackdrop{
    position: fixed;
    height: calc(100vh - 140px);
    width: 20%;
    right: 0;
    top: 140px;
    display: flex;
    align-content: right;
    justify-content: center;
    flex-direction: column;
    padding: 0 3%;
}
.navBackdrop .links{

}
.links{
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: right;
    flex-direction: column;
    margin-bottom: 15px;
}
.textLinks{
    /* opacity: 0;
    transform: translateX(50px); */
}
.links a {
    text-decoration: none;
}
@keyframes navLinkFade{
  from{
    opacity: 0;
    transform: translateX(50px);
  }
  to{
    opacity: 1;
    transform: translateX(0px);
  }
}
@media screen and (max-width:1180px){
  .burger{
    right: 5%;
  }
  .nav,
  .navBackdrop{
    top: 104px;
    padding: 0 5%;
    height: calc(100vh - 104px);
    width: 40%;
  }
}
@media screen and (max-width:780px){
  .nav,
  .navBackdrop{
    width: 50%;
  }
  .burger{
    top: 40%;
  }
  
}
</style>