import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import App from "./App.vue";
import HomeSection from "./components/sections/HomeSection.vue";
import ContactSection from "./components/sections/ContactSection.vue";
import OfertaSection from "./components/sections/ofertaSection.vue";
import { VueReCaptcha } from "vue-recaptcha-v3";

/* FIREBASE */

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBaxik562zTGoUni_BN90RPUkvO-MNQTPw",
  authDomain: "informatyk1authapp.firebaseapp.com",
  projectId: "informatyk1authapp",
  storageBucket: "informatyk1authapp.appspot.com",
  messagingSenderId: "841182541675",
  appId: "1:841182541675:web:068c7e987ff99323ee3e2e",
};

// Initialize Firebase
initializeApp(firebaseConfig);

/* FIREBASE */

/* FONTAWESOME */
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faCookieBite } from "@fortawesome/free-solid-svg-icons";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { faMobileScreenButton } from "@fortawesome/free-solid-svg-icons";
import { faDisplay } from "@fortawesome/free-solid-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";

/* add icons to the library */
library.add(faEnvelope);
library.add(faMobileScreenButton);
library.add(faDisplay);
library.add(faFacebook);
library.add(faCookieBite);
library.add(faX);

/* FONTAWESOME */

import TheHeader from "./components/UI/TheHeader.vue";
import TheFooter from "./components/UI/TheFooter.vue";
import TheCookieModal from "./components/UI/TheCookiePopUp.vue";
import TheLogo from "./components/UI/TheLogo.vue";
import TheBurger from "./components/UI/TheBurger.vue";
import Btn from "./components/UI/TheHeroNavBtn.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: HomeSection },
    { path: "/kontakt", component: ContactSection },
    { path: "/oferta", component: OfertaSection },
  ],
});

const app = createApp(App);
app.component("the-header", TheHeader);
app.component("the-footer", TheFooter);
app.component("the-cookieModal", TheCookieModal);
app.component("the-logo", TheLogo);
app.component("the-burger", TheBurger);
app.component("btn-btn", Btn);

app.component("font-awesome-icon", FontAwesomeIcon);
app.use(VueReCaptcha, { siteKey: "6LeKPfMqAAAAAO1e_mb7rqyQXvd4fJgBwwCPJhei" });
app.use(router);
router.isReady().then(function () {
  app.mount("#app");
});
