<template>
  <div id="form" class="footer__Section">
    <div class="footer__Section1">
      <div class="desc">
        <h1>W KONTAKCIE</h1>
        <h2>
          Zgłoś problem, zapytaj o wycenę lub zostaw nam ocenę. Odpowiemy
          najszybciej jak to możliwe!
        </h2>
        <div class="tools">
          <font-awesome-icon icon="fa-solid fa-envelope" />
          <a href="mailto:biuro@informatyk1.pl">biuro@informatyk1.pl</a>
        </div>
        <div class="tools">
          <font-awesome-icon icon="fa-solid fa-mobile-screen-button" />
          <a href="callto:+48 660 920 940">+48 660 920 940</a>
        </div>
      </div>
      <div class="form__Container">
        <slot>
          <the-mail-form></the-mail-form>
        </slot>
      </div>
    </div>
    <div class="footer__Section2">
      <div class="i1Footer">
        <a href="home">
          <the-logo :width="'500px'" :height="'200px'"></the-logo>
        </a>
        <a href="https://i-instal.pl">
          <img class="iinstal" src="/assets/pobrane.png" alt="" />
        </a>
      </div>
      <div class="onasFooter">
        <h1>Kompleksowa obsługa!</h1>
        <h2>
          Oprócz obsługi IT świadczymy także usługi instalacyjne oraz
          elektryczne. Naszym celem jest w pełni zaspokoić Twoje potrzeby!
        </h2>
        <a href="">Sprawdź I-Instal.</a>
      </div>
    </div>
    <div class="links">
      <h1>Copyright Informatyk1 2025</h1>
      <h1><a href="">Polityka prywatności</a></h1>
      <h1 class="delete"><a href="https://i-instal.pl">I-Instal.pl</a></h1>
      <h1 class="delete"><a href="">Facebook</a></h1>
      <h1><a href="http://nicode.pl">Poweredby Nicode.pl</a></h1>
    </div>
  </div>
</template>

<script>
import TheLogo from "./TheLogo.vue";
import TheMailForm from "./TheMailForm.vue";
export default {
  components: {
    TheMailForm,
    TheLogo,
  },
};
</script>

<style scoped>
.line {
  width: 90%;
  height: 4px;
  left: 5%;
  background-color: rgb(180, 22, 95);
  position: absolute;
  margin-top: 2%;
  border-radius: 20px;
}
.footer__Section {
  position: relative;
  display: flex;
  align-content: center;
  justify-content: space-between;
  flex-direction: column;
  height: auto;
}
.footer__Section1 .desc {
  max-width: 50%;
  z-index: 22;
}
.footer__Section1 .desc h1 {
  margin-bottom: 2rem;
  font-weight: 500;
}
.footer__Section1 .desc h2 {
  line-height: 2rem;
  font-size: 1.5rem;
  font-weight: 300;
}
.footer__Section1 {
  padding: 10% 10%;
  background-color: white;
  display: flex;
  align-items: flex-start;
  justify-content: left;
  max-height: 450px;
}
.form__Container {
  box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.25);
  min-width: 550px;
  z-index: 222;
  position: absolute;
  right: 10%;
  top: 10%;
}
.footer__Section2 {
  padding: 2rem;
  position: relative;
  padding: 10% 10%;
  display: flex;
  align-items: flex-end;
  justify-content: left;
  background-image: linear-gradient(
      419deg,
      rgba(71, 71, 71, 0.05) 0%,
      rgba(71, 71, 71, 0.05) 27%,
      rgba(209, 209, 209, 0.05) 27%,
      rgba(209, 209, 209, 0.05) 100%
    ),
    linear-gradient(
      474deg,
      rgba(63, 63, 63, 0.05) 0%,
      rgba(63, 63, 63, 0.05) 43%,
      rgba(138, 138, 138, 0.05) 43%,
      rgba(138, 138, 138, 0.05) 100%
    ),
    linear-gradient(
      503deg,
      rgba(58, 58, 58, 0.05) 0%,
      rgba(58, 58, 58, 0.05) 24%,
      rgba(100, 100, 100, 0.05) 24%,
      rgba(100, 100, 100, 0.05) 100%
    ),
    linear-gradient(
      416deg,
      rgba(47, 47, 47, 0.05) 0%,
      rgba(47, 47, 47, 0.05) 45%,
      rgba(208, 208, 208, 0.05) 45%,
      rgba(208, 208, 208, 0.05) 100%
    ),
    linear-gradient(270deg, rgb(209, 54, 202), rgb(180, 22, 95));
}
.i1Footer {
  color: white;
  display: flex;
  align-items: center;
  justify-content: left;
  flex-direction: column;
}
.iinstal {
  margin-top: 1rem;
  width: 200px;
}
.i1Footer h2 {
  font-size: 1rem;
  font-weight: 300;
}
.onasFooter {
  margin-left: 4rem;
  color: white;
  max-width: 30%;
}
.onasFooter a {
  text-decoration: none;
  color: lightgrey;
}
.onasFooter h1 {
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 1rem;
}
.onasFooter h2 {
  font-size: 1rem;
  font-weight: 300;
  margin-bottom: 0.5rem;
}
.tools {
  margin: 1rem 0;
}

.tools a {
  text-decoration: none;
  color: inherit;
  margin-left: 1rem;
  transition: 0.3s all;
}
.tools a:hover {
  color: rgb(180, 22, 95);
}
svg {
  font-size: 1.5rem;
  color: rgb(180, 22, 95);
}
.links {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 1rem 3%;
  background-color: rgba(255, 255, 255, 0.3);
}
.links h1 {
  font-size: 0.8rem;
  color: white;
  font-weight: 300;
}
.links a {
  text-decoration: none;
  color: white;
}
@media screen and (max-width: 1530px) {
  .tools a:hover {
    color: rgb(196, 196, 196);
  }
  .footer__Section1 {
    flex-direction: column;
    max-height: 100%;
    background-color: transparent;
    color: white;
  }
  svg {
    color: white;
  }
  .footer__Section2 {
    background-image: unset;
    padding-top: 0;
    margin-bottom: 2.5rem;
  }
  .form__Container {
    position: relative;
    margin: 2rem 10%;
    color: black;
  }
}
@media screen and (max-width: 1000px) {
  .links {
    padding: 1rem 10%;
  }
  .links h1 {
    font-size: 0.8rem;
    color: white;
    font-weight: 300;
  }
  .delete {
    display: none;
  }
  .i1Footer h2 {
    font-size: 0.8rem;
    font-weight: 300;
  }
  .form__Container {
    min-width: unset;
    width: 100%;
  }
  .onasFooter {
    margin: 1rem 0;
    color: white;
    max-width: 100%;
  }
  .onasFooter h1 {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 1rem;
  }
  .onasFooter h2 {
    font-size: 0.8rem;
    font-weight: 300;
  }
  .footer__Section2 {
    padding: 2rem;
    position: relative;
    padding: 0 10%;
    padding-bottom: 5%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .footer__Section1 .desc {
    max-width: unset;
    widows: 100%;
    z-index: 22;
  }
  .footer__Section1 .desc h1 {
    margin-bottom: 1.2rem;
    font-weight: 500;
  }
  .footer__Section1 .desc h2 {
    line-height: unset;
    font-size: 1rem;
    font-weight: 300;
  }
  svg {
    font-size: 1.2rem;
  }
}
</style>
