<template>
  <div class="box" :style="image">
    <div class="textBox">
      <h1>{{ heading }}</h1>
      <div class="line"></div>
      <h2>{{ content }}</h2>
    </div>
  </div>
</template>

<script>
export default {
  props: ['heading', 'content', 'bg'],
  data(){
    return{
      image: { backgroundImage: `url(${this.bg})` }
    };
  },
}
</script>

<style scoped>
.box{
  position: relative;
  width: 100%;
  height: calc(100vh - 141px);
  padding: 2rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #81316a;
  background-blend-mode: multiply;
  scroll-snap-align: start;
  transition: 0.4s all;
}
.textBox::before{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 0.4rem;
    height: 100%;
    background-color: white;
    z-index: 1;
}
.textBox{
  position: relative;
  color: white;
  width: 60%;
  padding: 1rem 2rem;
  margin: 1rem 1rem;
  z-index: 10;
}
.line{
  width: 50px;
  height: 3px;
  margin: 2rem 0;
  background-color: white;
}
h1{
  font-weight: 400;
  font-size: 2.5rem;
}
h2{
  font-weight: 300;
}
@media screen and (max-width:1180px){
  .box{
    height: calc(100vh - 104px);
  }
  h1{
    font-weight: 400;
    font-size: 2rem;
  }
  h2{
    font-weight: 300;
    font-size: 1.3rem;
  }
}
@media screen and (max-width:880px){
  .box{
    height: calc(100vh - 104px);
    padding: 0.7rem;
  }
  .textBox{
    width: 80%;
  }
  h1{
    font-weight: 400;
    font-size: 1.5rem;
  }
  h2{
    font-weight: 300;
    font-size: 1rem;
  }
  .textBox::before{
    width: 0.3rem;
}
}

</style>